import React, { FC, memo } from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'

import { NotFoundTemplate } from '../../templates'
import { Layout } from '../../components'
import routes from '../../router/routes'

const NotFoundContainer: FC = () => {
  const intl = useIntl()

  // return

  return (
    <Layout>
      <NotFoundTemplate
        backgroundImage="/images/storybook/templates/homepage/background-web.jpg"
        title={intl.formatMessage({ id: 'notFound.title' })}
        text={intl.formatMessage({ id: 'notFound.text' })}
        link={routes.root.path}
        linkLabel={intl.formatMessage({ id: 'notFound.linkLabel' })}
      />
    </Layout>
  )
}

export default memo(NotFoundContainer)
